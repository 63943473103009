import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography, Grid, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import { CreateTemplate, SelectTemplate, UpdateTemplate } from '../../../redux/actions/TemplateActions';
import { RichEditor } from '../../common/RichEditor';

export default function TemplateEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');
  const [template, setTemplate] = useState('');
  const [templateType, setTemplateType] = useState('');
  const { templates, selectedTemplate, templateLoading, error, actionStatus } = useSelector(state => state.templates);
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    dispatch(ResetActionStatus())
  }, [dispatch])

  useEffect(() => {
    if (selectedTemplate && templates.length > 0) {
      const template = templates.find(template => template.id === selectedTemplate)
      setName(template.name)
      setTemplate(template.template)
      setTemplateType(template.template_type)
    }
  }, [selectedTemplate, templates])

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push(`/templates`)
    }
  }, [actionStatus, history])

  const handleTemplate = () => {
    const error = {};
    if (!name) {
      error.name = 'Name is Required';
    }
    if (!template) {
      error.template = 'Template is Required';
    }
    if (!templateType) {
      error.templateType = 'Template Type is Required';
    }
    setValidationError(error)
    if (Object.keys(error).length > 0) {
      return
    }

    const data = {
      name: name,
      template: template,
      template_type: templateType
    }

    if (selectedTemplate) {
      dispatch(UpdateTemplate(selectedTemplate, data))
    } else {
      dispatch(CreateTemplate(data))
    }
  }

  const handleCancel = () => {
    if (selectedTemplate) {
      dispatch(SelectTemplate(null))
    }
    history.push('/templates')
  }

  if (templateLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedTemplate ? 'Edit Template' : 'Create Template'}</Typography>
          <Box my={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => { setName(e.target.value) }}
                  error={!!validationError.name}
                  helperText={validationError.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Template Type"
                  variant="outlined"
                  fullWidth
                  value={templateType}
                  onChange={(e) => { setTemplateType(e.target.value) }}
                  error={!!validationError.templateType}
                  helperText={validationError.templateType}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <RichEditor
                  value={template}
                  onChange={setTemplate}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleTemplate()}>
            {selectedTemplate ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}