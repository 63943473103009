import HttpService from './HttpService';

export const GetMarketingsService = () => {
    const http = new HttpService();
    const url = "marketings";
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const CreateMarketingsService = (inputData) => {
    const http = new HttpService();
    const url = "marketings/create";
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const UpdateMarketingsService = (id, inputData) => {
    const http = new HttpService();
    const url = "marketings/update/" + id;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const DeleteMarketingsService = (id) => {
    const http = new HttpService();
    const url = "marketings/delete/" + id;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}
