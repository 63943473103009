import React, { useEffect } from 'react';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import Spinner from '../../common/Spinner';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import { DeleteDepartment, LoadDepartments, SelectDepartment } from '../../../redux/actions/DepartmentActions';
import { SelectAccount } from '../../../redux/actions/AccountActions';

export default function DepartmentList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { accounts, selectedAccount } = useSelector(state => state.accounts);
  const { departments, departmentLoading, error } = useSelector(state => state.departments);
  const account = accounts.find((account) => account.id === selectedAccount);

  useEffect(() => {
    dispatch(ResetActionStatus())
    if (selectedAccount) {
      dispatch(LoadDepartments(selectedAccount))
    } else {
      history.push('/accounts');
    }
  }, [dispatch, selectedAccount, history])

  const handleCreateDepartment = () => {
    dispatch(SelectDepartment(null))
    history.push(`/account/${selectedAccount}/department/create`)
  }

  const handleEdit = (id) => {
    dispatch(SelectDepartment(id))
    history.push('/department/edit/' + id)
  }

  const handleView = (id) => {
    dispatch(SelectDepartment(id))
    history.push(`/department/${id}/personas`)
  }

  const handleDelete = (id) => {
    dispatch(DeleteDepartment(id))
  }

  const handleCancel = () => {
    if (selectedAccount) {
      dispatch(SelectAccount(null))
    }
    history.push(`/accounts`)
  }

  if (departmentLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        (error || !account) && (
          <Box>{error}</Box>
        )
      }
      {
        (departments || []).length === 0 ? (
          <>
            <Box className={classes.fullContainer}>
              <AccountBoxIcon style={{fontSize: 48}} />
              <Typography variant="h5">No Department</Typography>
              <Typography variant="body1" style={{marginTop: 8}}>{account.name} doesn't have any department</Typography>
              <Typography variant="body1" style={{marginBottom: 15}}>Please create an department</Typography>
              <Box display="flex">
                <Button size="small" variant="contained" onClick={handleCancel}>Back</Button>
                <Button size="small" variant="contained" color="primary" onClick={handleCreateDepartment}>Create Department</Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Box display="flex" alignItems="center">
                <IconButton onClick={handleCancel}><ArrowBackIcon /></IconButton>
                <Typography variant="h5">{account.name}'s Department</Typography>
              </Box>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateDepartment}>Add Department</Button>
            </Box>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    departments.map((department) => (
                      <TableRow key={department.id}>
                        <TableCell>{department.id}</TableCell>
                        <TableCell>{department.name}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(department.id)}><EditIcon color="primary"/></IconButton>
                          <IconButton onClick={() => handleView(department.id)}><VisibilityIcon color="action"/></IconButton>
                          <IconButton onClick={() => handleDelete(department.id)}><DeleteIcon color="secondary"/></IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </>
  )
}