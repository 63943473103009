import React, { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import { CreateProspect, SelectProspect, UpdateProspect } from '../../../redux/actions/ProspectActions';

export default function ProspectEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { selectedPersona } = useSelector(state => state.personas);
  const { prospects, selectedProspect, prospectLoading, error, actionStatus } = useSelector(state => state.prospects);

  useEffect(() => {
    if (!selectedPersona) {
      history.push('/accounts');
    }
  }, [selectedPersona, history])

  useEffect(() => {
    if (prospects && selectedProspect) {
      const prospect = prospects.find((prospect) => prospect.id === selectedProspect)
      setFirstName(prospect.first_name);
      setLastName(prospect.last_name);
      setEmail(prospect.email);
      setPhoneNumber(prospect.phone_number);
    }
  }, [selectedProspect, prospects])

  const handleCancel = () => {
    if (selectedProspect) {
      dispatch(SelectProspect(null))
    }
    history.push(`/persona/${selectedPersona}/prospects`)
  }

  const handleProspect = () => {
    if (selectedProspect) {
      if (firstName) {
        dispatch(UpdateProspect(selectedProspect, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber
        }))
      }
    } else {
      if (firstName) {
        dispatch(CreateProspect({
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber
        }, selectedPersona))
      }
    }
  }

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push(`/persona/${selectedPersona}/prospects`)
    }
  }, [actionStatus, history, selectedPersona])

  if (prospectLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedProspect ? 'Edit Prospect' : 'Create Prospect'}</Typography>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="email"
                  variant="outlined"
                  fullWidth
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="tel"
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleProspect()}>
            {selectedProspect ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}