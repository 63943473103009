
import { GetProspectsService, CreateProspectsService, UpdateProspectsService, DeleteProspectsService } from '../../services/ProspectServices';
import * as ActionTypes from '../ActionTypes';

export const LoadProspects = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROSPECTS_LOADING });
        GetProspectsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PROSPECTS_LOAD_SUCCESS, prospects: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PROSPECTS_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PROSPECTS_LOAD_FAILED, message: error.message })
        })
    }
}

export const CreateProspect = (data, id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROSPECT_CREATING });
        CreateProspectsService(data, id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PROSPECT_CREATE_SUCCESS, prospect: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PROSPECT_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PROSPECT_CREATE_FAILED, message: error.message });
        })
    }
}

export const UpdateProspect = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROSPECT_UPDATING });
        UpdateProspectsService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PROSPECT_UPDATE_SUCCESS, prospect: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PROSPECT_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PROSPECT_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeleteProspect = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROSPECT_DELETING });
        dispatch(SelectProspect(id))
        DeleteProspectsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PROSPECT_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PROSPECT_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PROSPECT_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectProspect = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PROSPECT_SELECT,
            selectedProspect: id
        })
    }
}