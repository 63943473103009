import React, { useEffect } from 'react';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteTemplate, SelectTemplate } from '../../../redux/actions/TemplateActions';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import Spinner from '../../common/Spinner';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { LoadTemplates } from '../../../redux/actions/TemplateActions';

export default function TemplateList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { templates, templateLoading, error } = useSelector(state => state.templates);

  useEffect(() => {
    dispatch(ResetActionStatus())
    dispatch(LoadTemplates())
  }, [dispatch])

  const handleCreateTemplate = () => {
    dispatch(SelectTemplate(null))
    history.push('/template/create')
  }

  const handleEdit = (id) => {
    dispatch(SelectTemplate(id))
    history.push('/template/edit/' + id)
  }

  const handleDelete = (id) => {
    dispatch(DeleteTemplate(id))
  }

  if (templateLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      {
        templates.length === 0 ? (
          <>
            <Box className={classes.fullContainer}>
              <AccountBoxIcon style={{fontSize: 48}} />
              <Typography variant="h5">No Template</Typography>
              <Typography variant="body1" style={{marginBottom: 15}}>Please create an template</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateTemplate}>Create Template</Button>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Typography variant="h5">Templates</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateTemplate}>Add Template</Button>
            </Box>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    templates.map((template) => (
                      <TableRow key={template.id}>
                        <TableCell>{template.id}</TableCell>
                        <TableCell>{template.name}</TableCell>
                        <TableCell>{template.template_type}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(template.id)}><EditIcon color="primary"/></IconButton>
                          <IconButton onClick={() => handleDelete(template.id)}><DeleteIcon color="error"/></IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </>
  )
}