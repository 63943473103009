import * as ActionTypes from '../ActionTypes';

const initState = {
  marketings: [],
  selectedMarketing: null,
  marketLoading: false,
  error: null,
  actionStatus: null
};

const MarketingReducer = (state = initState, action) => {
  const marketings = [...state.marketings];
  const index = marketings.findIndex((account) => account.id === state.selectedMarketing)
  switch (action.type) {
    case ActionTypes.MARKETINGS_LOADING:
      case ActionTypes.MARKETING_CREATING:
      case ActionTypes.MARKETING_UPDATING:
      case ActionTypes.MARKETING_DELETING:
      return {
        ...state,
        marketingLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.MARKETINGS_LOAD_FAILED:
      case ActionTypes.MARKETING_CREATE_FAILED:
      case ActionTypes.MARKETING_UPDATE_FAILED:
      case ActionTypes.MARKETING_DELETE_FAILED:
      return {
        ...state,
        marketingLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.MARKETINGS_LOAD_SUCCESS:
      return {
        ...state,
        marketings: action.marketings,
        marketingLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.MARKETING_CREATE_SUCCESS:
      return {
        ...state,
        marketings: [...state.marketings, action.marketing],
        marketingLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.MARKETING_UPDATE_SUCCESS:
      marketings.splice(index, 1, action.marketing)
      return {
        ...state,
        marketings: marketings,
        marketingLoading: false,
        selectedMarketing: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.MARKETING_DELETE_SUCCESS:
      marketings.splice(index, 1)
      return {
        ...state,
        marketings: marketings,
        marketingLoading: false,
        selectedMarketing: null,
        error: null,
      };

    case ActionTypes.MARKETING_SELECT:
      return {
        ...state,
        actionStatus: null,
        selectedMarketing: action.selectedMarketing,
        error: null,
      };

    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        marketingLoading: false,
      }
    default:
      return state;
  }
};

export default MarketingReducer;

