import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, Card, CardContent, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadDepartments } from '../../../redux/actions/DepartmentActions';
import { LoadPersonas } from '../../../redux/actions/PersonaActions';
import { LoadAcccounts } from '../../../redux/actions/AccountActions';
import { LoadTemplates } from '../../../redux/actions/TemplateActions';
import Spinner from '../../common/Spinner';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import { LoadMarketings } from '../../../redux/actions/MarketingActions';

const Script = () => {
  const [accountId, setAccountId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [personaId, setPersonaId] = useState(0);
  const [templateId, setTemplateId] = useState(0);
  const [marketingId, setMarketingId] = useState(0);
  const [filteredMarketings, setFilteredMarketings] = useState([]);
  const [builtScript, setBuiltScript] = useState('');
  const { accounts, accountLoading } = useSelector(state => state.accounts);
  const { departments, departmentLoading } = useSelector(state => state.departments);
  const { personas, personaLoading } = useSelector(state => state.personas);
  const { marketings, marketingsLoading } = useSelector(state => state.marketings);
  const { templates, templateLoading } = useSelector(state => state.templates);
  const { userInfo } = useSelector( state=> state.userAuth )
  const [validationError, setValidationError] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LoadAcccounts())
    dispatch(LoadTemplates())
    dispatch(LoadMarketings())
    dispatch(ResetActionStatus())
  }, [dispatch])

  useEffect(() => {
    filterMarketings()
    if (accountId) {
      dispatch(LoadDepartments(accountId))
    }
  }, [accountId, dispatch])

  useEffect(() => {
    filterMarketings()
    if (departmentId) {
      dispatch(LoadPersonas(departmentId))
    }
  }, [departmentId, dispatch])

  useEffect(() => {
    filterMarketings()
  }, [personaId])

  useEffect(() => {
    setFilteredMarketings(marketings);
  }, [marketings])

  const filterMarketings = () => {
    let data = marketings
    if (accountId) {
      data = data.filter((marketing) => marketing.account.id === accountId)
    }
    if (accountId && departmentId) {
      data = data.filter((marketing) => marketing.department.id === departmentId)
    }
    if (accountId && departmentId && personaId) {
      data = data.filter((marketing) => marketing.persona.id === personaId)
    }
    if (data.length === 1) {
      setMarketingId(data[0].id);
    }
    setFilteredMarketings(data);
  }

  const buildScript = () => {
    const error = {};
    if (!accountId) {
      error.account = 'Account is Required';
    }
    if (!departmentId) {
      error.department = 'Deparment is Required';
    }
    if (!personaId) {
      error.persona = 'Persona is Required';
    }
    if (!templateId) {
      error.template = 'Template is Required';
    }
    if (!marketingId) {
      error.marketing = 'Marketing Knowledge is Required';
    }
    setValidationError(error)
    if (Object.keys(error).length > 0) {
      return
    }

    const template = templates.find((template) => template.id === templateId)

    let template_content = template.template;
    const account = accounts.find(account => account.id === accountId)
    const department = departments.find(department => department.id === departmentId)
    const persona = personas.find(persona => persona.id === personaId)
    const marketing = marketings.find(marketing => marketing.id === marketingId)

    const result_content = template_content.replaceAll('{{account_name}}', account.name)
      .replaceAll('{{department_name}}', department.name)
      .replaceAll('{{persona_name}}', persona.name)
      .replaceAll('{{marketing_benefit}}', marketing.benefits)
      .replaceAll('{{marketing_valueProposition}}', marketing.value_proposition)
      .replaceAll('{{user_name}}', userInfo.name)
      .replaceAll('{{user_email}}', userInfo.email)
      .replaceAll('{{user_firstname}}', userInfo.name.split(' ')[0])
      .replaceAll('{{user_lastname}}', userInfo.name.split(' ')[1])
    setBuiltScript(result_content);
  }

  if (accountLoading || departmentLoading || personaLoading || templateLoading || marketingsLoading) {
    return <Spinner />
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth error={!!validationError.account} required>
                <InputLabel id="account-label">Account</InputLabel>
                <Select
                  labelId="account-label"
                  value={accountId}
                  onChange={(e) => setAccountId(e.target.value)}
                  placeholder="Select Account"
                  label="Account"
                >
                  <MenuItem value={0}>Select Account</MenuItem>
                  {
                    accounts.map(account => (
                      <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem>
                    ))
                  }
                </Select>
                {
                  validationError.account &&
                  <FormHelperText>{validationError.account}</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth disabled={!accountId} error={!!validationError.department} required>
                <InputLabel id="department-label">Deparmtment</InputLabel>
                <Select
                  labelId="department-label"
                  value={departmentId}
                  onChange={(e) => setDepartmentId(e.target.value)}
                  placeholder="Select Deparmtment"
                  label="Deparmtment"
                >
                  <MenuItem value={0}>Select Deparmtment</MenuItem>
                  {
                    departments.map(department => (
                      <MenuItem value={department.id} key={department.id}>{department.name}</MenuItem>
                    ))
                  }
                </Select>
                {
                  validationError.department &&
                  <FormHelperText>{validationError.department}</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth disabled={!accountId || !departmentId} error={!!validationError.persona} required>
                <InputLabel id="persona-label">Persona</InputLabel>
                <Select
                  labelId="persona-label"
                  value={personaId}
                  onChange={(e) => setPersonaId(e.target.value)}
                  placeholder="Select Persona"
                  label="Persona"
                >
                  <MenuItem value={0}>Select Persona</MenuItem>
                  {
                    personas.map(persona => (
                      <MenuItem value={persona.id} key={persona.id}>{persona.name}</MenuItem>
                    ))
                  }
                </Select>
                {
                  validationError.persona &&
                  <FormHelperText>{validationError.persona}</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth error={!!validationError.template} required>
                <InputLabel id="persona-label">Template</InputLabel>
                <Select
                  labelId="persona-label"
                  value={templateId}
                  onChange={(e) => setTemplateId(e.target.value)}
                  placeholder="Select Template"
                  label="Template"
                >
                  <MenuItem value={0}>Select Template</MenuItem>
                  {
                    templates.map(template => (
                      <MenuItem value={template.id} key={template.id}>{template.name}</MenuItem>
                    ))
                  }
                </Select>
                {
                  validationError.template &&
                  <FormHelperText>{validationError.template}</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth error={!!validationError.marketing} required>
                <InputLabel id="persona-label">Marketing Knowledge</InputLabel>
                <Select
                  labelId="persona-label"
                  value={marketingId}
                  onChange={(e) => setMarketingId(e.target.value)}
                  placeholder="Select Marketing"
                  label="Marketing Knowledge"
                >
                  <MenuItem value={0}>Select Marketing</MenuItem>
                  {
                    filteredMarketings.map(marketing => (
                      <MenuItem value={marketing.id} key={marketing.id}>{marketing.name}</MenuItem>
                    ))
                  }
                </Select>
                {
                  validationError.marketing &&
                  <FormHelperText>{validationError.marketing}</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Button color="primary" onClick={buildScript} variant="contained" fullWidth>Build</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {builtScript && (
      <Card style={{marginTop: 12}}>
        <CardContent>
            <div dangerouslySetInnerHTML={{ __html: builtScript }}/>
        </CardContent>
      </Card>
      )}
    </>
  )
}

export default Script