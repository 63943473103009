import * as ActionTypes from '../ActionTypes';

const initState = {
  prospects: [],
  selectedProspect: null,
  prospectLoading: false,
  error: null,
  actionStatus: null
};

const ProspectReducer = (state = initState, action) => {
  const prospects = [...state.prospects];
  const index = prospects.findIndex((prospect) => prospect.id === state.selectedProspect)
  switch (action.type) {
    case ActionTypes.PROSPECTS_LOADING:
    case ActionTypes.PROSPECT_CREATING:
    case ActionTypes.PROSPECT_UPDATING:
    case ActionTypes.PROSPECT_DELETING:
      return {
        ...state,
        prospectLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.PROSPECTS_LOAD_FAILED:
    case ActionTypes.PROSPECT_CREATE_FAILED:
    case ActionTypes.PROSPECT_UPDATE_FAILED:
    case ActionTypes.PROSPECT_DELETE_FAILED:
      return {
        ...state,
        prospectLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.PROSPECTS_LOAD_SUCCESS:
      return {
        ...state,
        prospects: action.prospects,
        prospectLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PROSPECT_CREATE_SUCCESS:
      return {
        ...state,
        prospects: [...state.prospects, action.prospect],
        prospectLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PROSPECT_UPDATE_SUCCESS:
      prospects.splice(index, 1, action.prospect)
      return {
        ...state,
        prospects: prospects,
        prospectLoading: false,
        selectedProspect: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PROSPECT_DELETE_SUCCESS:
      prospects.splice(index, 1)
      return {
        ...state,
        prospects: prospects,
        prospectLoading: false,
        selectedProspect: null,
        error: null,
      };

    case ActionTypes.PROSPECT_SELECT:
      return {
        ...state,
        selectedProspect: action.selectedProspect,
        actionStatus: null
      };
    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        prospectLoading: false,
      }
    default:
      return state;
  }
};

export default ProspectReducer;

