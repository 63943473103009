import React, { useEffect } from 'react';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAccount, LoadAcccounts, SelectAccount } from '../../../redux/actions/AccountActions';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import Spinner from '../../common/Spinner';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';

export default function AccountList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { accounts, accountLoading, error } = useSelector(state => state.accounts);

  useEffect(() => {
    dispatch(ResetActionStatus())
    dispatch(LoadAcccounts())
  }, [dispatch])

  const handleCreateAccount = () => {
    dispatch(SelectAccount(null))
    history.push('/account/create')
  }

  const handleEdit = (id) => {
    dispatch(SelectAccount(id))
    history.push('/account/edit/' + id)
  }

  const handleView = (id) => {
    dispatch(SelectAccount(id))
    history.push(`/account/${id}/departments`)
  }

  const handleDelete = (id) => {
    dispatch(DeleteAccount(id))
  }

  if (accountLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      {
        accounts.length === 0 ? (
          <>
            <Box className={classes.fullContainer}>
              <AccountBoxIcon style={{fontSize: 48}} />
              <Typography variant="h5">No Account</Typography>
              <Typography variant="body1" style={{marginBottom: 15}}>Please create an account</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateAccount}>Create Account</Button>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Typography variant="h5">Accounts</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateAccount}>Add Account</Button>
            </Box>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    accounts.map((account) => (
                      <TableRow key={account.id}>
                        <TableCell>{account.id}</TableCell>
                        <TableCell>{account.name}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(account.id)}><EditIcon color="primary"/></IconButton>
                          <IconButton onClick={() => handleView(account.id)}><VisibilityIcon color="action"/></IconButton>
                          <IconButton onClick={() => handleDelete(account.id)}><DeleteIcon color="error"/></IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </>
  )
}