import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from "./components/pages/HomeComponent";
import Login from "./components/pages/LoginComponent";
import Register from "./components/pages/RegisterComponent";

import PrivateRoute from './PrivateRoute';
import { Guard } from './Guard';


import Header from './components/layouts/Header';



function Routes() {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/home" component={Home} />
        <Guard path="/user/login" token="user-token" checkingFlag={false} routeRedirect="/dashboard" component={Login} />
        <Guard path="/user/register" token="user-token" checkingFlag={false} routeRedirect="/dashboard" component={Register} />

        <Guard path="/" token="user-token" checkingFlag={true} routeRedirect="/user/login" component={PrivateRoute} />
      </Switch>
    </>

  );
}


export default Routes;