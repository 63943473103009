
import { GetDepartmentsService, CreateDepartmentsService, UpdateDepartmentsService, DeleteDepartmentsService } from '../../services/DepartmentServices';
import * as ActionTypes from '../ActionTypes';

export const LoadDepartments = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.DEPARTMENTS_LOADING });
        GetDepartmentsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.DEPARTMENTS_LOAD_SUCCESS, departments: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.DEPARTMENTS_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.DEPARTMENTS_LOAD_FAILED, message: error.message })
        })
    }
}

export const CreateDepartment = (data, id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.DEPARTMENT_CREATING });
        CreateDepartmentsService(data, id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.DEPARTMENT_CREATE_SUCCESS, department: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.DEPARTMENT_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.DEPARTMENT_CREATE_FAILED, message: error.message });
        })
    }
}

export const UpdateDepartment = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.DEPARTMENT_UPDATING });
        UpdateDepartmentsService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.DEPARTMENT_UPDATE_SUCCESS, department: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.DEPARTMENT_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.DEPARTMENT_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeleteDepartment = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.DEPARTMENT_DELETING });
        dispatch(SelectDepartment(id))
        DeleteDepartmentsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.DEPARTMENT_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.DEPARTMENT_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.DEPARTMENT_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectDepartment = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.DEPARTMENT_SELECT,
            selectedDepartment: id
        })
    }
}