import React, { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAccount, SelectAccount, UpdateAccount } from '../../../redux/actions/AccountActions';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';

export default function AccountEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');

  const { accounts, selectedAccount, accountLoading, error, actionStatus } = useSelector(state => state.accounts);

  useEffect(() => {
    if (accounts && selectedAccount) {
      const account = accounts.find((account) => account.id === selectedAccount)
      setName(account.name);
    }
  }, [selectedAccount, accounts])

  const handleCancel = () => {
    if (selectedAccount) {
      dispatch(SelectAccount(null))
    }
    history.push('/accounts')
  }

  const handleAccount = () => {
    if (selectedAccount) {
      if (name) {
        dispatch(UpdateAccount(selectedAccount, {
          name: name
        }))
      }
    } else {
      if (name) {
        dispatch(CreateAccount({
          name: name
        }))
      }
    }
  }

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push('/accounts')
    }
  }, [actionStatus, history])

  if (accountLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedAccount ? 'Edit Account' : 'Create Account'}</Typography>
          <Box paddingY={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleAccount()}>
            {selectedAccount ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}