import HttpService from './HttpService';

export const GetTemplatesService = () => {
    const http = new HttpService();
    const url = "templates";
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const CreateTemplatesService = (inputData) => {
    const http = new HttpService();
    const url = "templates/create";
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const UpdateTemplatesService = (id, inputData) => {
    const http = new HttpService();
    const url = "templates/update/" + id;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const DeleteTemplatesService = (id) => {
    const http = new HttpService();
    const url = "templates/delete/" + id;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}
