import React from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Editor extends React.Component {
  componentDidMount() {
    const variablePickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-variable .ql-picker-item'));

    variablePickerItems.forEach(item => item.textContent = item.dataset.value);

    document.querySelector('.ql-variable .ql-picker-label').innerHTML
        = 'Insert Variable&nbsp;&nbsp;&nbsp;&nbsp;' + document.querySelector('.ql-variable .ql-picker-label').innerHTML;
  }
  render() {
    return (
      <div className="text-editor">
        <div id="toolbar">
          <select className="ql-header">
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option selected></option>
          </select>
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-link"></button>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>
          <select className="ql-variable">
            <option value="{{user_name}}" />
            <option value="{{user_firstname}}" />
            <option value="{{user_lastname}}" />
            <option value="{{marketing_benefit}}" />
            <option value="{{marketing_valueProposition}}" />
            <option value="{{prospect_name}}" />
            <option value="{{account_name}}" />
            <option value="{{department_name}}" />
            <option value="{{persona_name}}" />
            <option value="{{research_name}}" />
          </select>
        </div>
        <ReactQuill
          onChange={this.props.onChange}
          modules={Editor.modules}
          formats={Editor.formats}
          value={this.props.value}
          theme={"snow"}
        />
      </div>
    )
  }
};

function insertVariable(value) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

Editor.modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      "variable": insertVariable
    }
  }
}

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];

export default Editor