
import { GetAccountsService, CreateAccountsService, UpdateAccountsService, DeleteAccountsService } from '../../services/AccountServices';
import * as ActionTypes from '../ActionTypes';

export const LoadAcccounts = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ACCOUNTS_LOADING });
        GetAccountsService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.ACCOUNTS_LOAD_SUCCESS, accounts: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.ACCOUNTS_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.ACCOUNTS_LOAD_FAILED, message: error.message })
        })
    }
}

export const CreateAccount = (data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ACCOUNT_CREATING });
        CreateAccountsService(data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.ACCOUNT_CREATE_SUCCESS, account: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.ACCOUNT_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.ACCOUNTS_LOAD_FAILED, message: error.message });
        })
    }
}

export const UpdateAccount = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ACCOUNT_UPDATING });
        UpdateAccountsService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.ACCOUNT_UPDATE_SUCCESS, account: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.ACCOUNT_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.ACCOUNT_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeleteAccount = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ACCOUNT_DELETING });
        dispatch(SelectAccount(id))
        DeleteAccountsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.ACCOUNT_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.ACCOUNT_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.ACCOUNT_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectAccount = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ACCOUNT_SELECT,
            selectedAccount: id
        })
    }
}