import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  homeRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leftSidebar: {
    width: 240,
    '& .MuiDrawer-paper': {
      width: 240,
      top: 64,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderTop: '1px solid',
      borderColor: theme.palette.primary.dark,
    },
    '& .MuiListItemIcon-root': {
      color: 'white'
    }
  },
  mainContainer: {
    marginLeft: 240,
    maxWidth: "calc(100% - 240px)",
    padding: 20,
    marginTop: 90,
  },
  spinnerContainer: {
    width: '100%',
    height: 'calc(100vh - 130px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullContainer: {
    width: '100%',
    height: 'calc(100vh - 130px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  containerDiv: {
    flex: 'auto',
    position: 'fixed',
    top: '40%',
    left: '42.5%',
  },
  extraBtnStyle: {
    margin: '2%'
  },
  centerItem: {
    width: '50%',
    margin: '90px auto'
  },
  authCard: {
    textAlign: 'center'
  },
  fullWidth: {
    width: '90%',
    marginBottom: '25px',
    marginTop: '20px'
  },
  linkContainer: {
    marginBottom: '20px'
  },
  authResponse: {
    fontWeight: 'bolder'
  },
  title: {
    flexGrow: 1,
  },
  fullWidthProfile: {
  },
  link: {
    color: 'white',
    textDecoration: "none"
  },
}));