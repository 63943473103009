import React, { useEffect, useState } from 'react';
import { AppBar, CssBaseline, Toolbar, Typography, Button } from '@material-ui/core';
import { useStyles } from '../../styles/styles';
import { LogoutAction } from '../../redux/actions/AuthActions';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import mainLogo from '../../logo.png';

export default function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const authStatus = useSelector(state => state.userAuth.authStatus);
  const logOut = () => {
    dispatch(LogoutAction());
  }

  const login = () => {
    history.push("/user/login");
  }


  useEffect(() => {
    if (authStatus === false) {
      // console.log(authResponse);
      localStorage.removeItem('user-token');
      history.push("/user/login");
    }
  }, [authStatus])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.header} elevation={0}>
        <Toolbar className={classes.navbar}>
          <Link to="/home" className={classes.link}>
            <img src={mainLogo} width="100px" />
          </Link>
          {
            authStatus ?
              <Button color="inherit" onClick={logOut}>Logout</Button> :
              <Button color="inherit" onClick={login}>Login</Button>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
