import HttpService from './HttpService';

export const GetAccountsService = () => {
    const http = new HttpService();
    const url = "accounts";
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const CreateAccountsService = (inputData) => {
    const http = new HttpService();
    const url = "accounts/create";
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const UpdateAccountsService = (id, inputData) => {
    const http = new HttpService();
    const url = "accounts/update/" + id;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const DeleteAccountsService = (id) => {
    const http = new HttpService();
    const url = "accounts/delete/" + id;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}
