import * as ActionTypes from '../ActionTypes';

const initState = {
  accounts: [],
  selectedAccount: null,
  accountLoading: false,
  error: null,
  actionStatus: null
};

const AccountReducer = (state = initState, action) => {
  const accounts = [...state.accounts];
  const index = accounts.findIndex((account) => account.id === state.selectedAccount)
  switch (action.type) {
    case ActionTypes.ACCOUNTS_LOADING:
    case ActionTypes.ACCOUNT_CREATING:
    case ActionTypes.ACCOUNT_UPDATING:
    case ActionTypes.ACCOUNT_DELETING:
      return {
        ...state,
        accountLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.ACCOUNTS_LOAD_FAILED:
    case ActionTypes.ACCOUNT_CREATE_FAILED:
    case ActionTypes.ACCOUNT_UPDATE_FAILED:
    case ActionTypes.ACCOUNT_DELETE_FAILED:
      return {
        ...state,
        accountLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.ACCOUNTS_LOAD_SUCCESS:
      return {
        ...state,
        accounts: action.accounts,
        accountLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        accounts: [...state.accounts, action.account],
        accountLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.ACCOUNT_UPDATE_SUCCESS:
      accounts.splice(index, 1, action.account)
      return {
        ...state,
        accounts: accounts,
        accountLoading: false,
        selectedAccount: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.ACCOUNT_DELETE_SUCCESS:
      accounts.splice(index, 1)
      return {
        ...state,
        accounts: accounts,
        accountLoading: false,
        selectedAccount: null,
        error: null,
      };

    case ActionTypes.ACCOUNT_SELECT:
      return {
        ...state,
        selectedAccount: action.selectedAccount,
        actionStatus: null
      };
    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        accountLoading: false,
      }
    default:
      return state;
  }
};

export default AccountReducer;

