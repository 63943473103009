import HttpService from './HttpService';

export const GetDepartmentsService = (id) => {
    const http = new HttpService();
    const url = `accounts/${id}/departments`;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const CreateDepartmentsService = (inputData, id) => {
    const http = new HttpService();
    const url = `accounts/${id}/departments/create`;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const UpdateDepartmentsService = (id, inputData) => {
    const http = new HttpService();
    const url = "departments/update/" + id;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const DeleteDepartmentsService = (id) => {
    const http = new HttpService();
    const url = "departments/delete/" + id;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}
