import { combineReducers } from 'redux';
import AccountReducer from './AccountReducer';
import AuthReducer from './AuthReducer';
import DepartmentReducer from './DepartmentReducer';
import MarketingReducer from './MarketingReducer';
import PersonaReducer from './PersonaReducer';
import ProfileReducer from "./ProfileReducer";
import ProspectReducer from './ProspectReducer';
import TemplateReducer from './TemplateReducer';

const RootReducer = combineReducers({
   userAuth: AuthReducer,
   userDetails: ProfileReducer,
   accounts: AccountReducer,
   departments: DepartmentReducer,
   personas: PersonaReducer,
   prospects: ProspectReducer,
   marketings: MarketingReducer,
   templates: TemplateReducer
});


export default RootReducer;