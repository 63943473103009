import * as ActionTypes from '../ActionTypes';

const initState = {
  departments: [],
  selectedDepartment: null,
  departmentLoading: false,
  error: null,
  actionStatus: null
};

const DepartmentReducer = (state = initState, action) => {
  const departments = [...(state.departments || [])];
  const index = departments.findIndex((department) => department.id === state.selectedDepartment)
  switch (action.type) {
    case ActionTypes.DEPARTMENTS_LOADING:
    case ActionTypes.DEPARTMENT_CREATING:
    case ActionTypes.DEPARTMENT_UPDATING:
    case ActionTypes.DEPARTMENT_DELETING:
      return {
        ...state,
        departmentLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.DEPARTMENTS_LOAD_FAILED:
    case ActionTypes.DEPARTMENT_CREATE_FAILED:
    case ActionTypes.DEPARTMENT_UPDATE_FAILED:
    case ActionTypes.DEPARTMENT_DELETE_FAILED:
      return {
        ...state,
        departmentLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.DEPARTMENTS_LOAD_SUCCESS:
      return {
        ...state,
        departments: action.departments || [],
        departmentLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.DEPARTMENT_CREATE_SUCCESS:
      return {
        ...state,
        departments: [...state.departments, action.department],
        departmentLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.DEPARTMENT_UPDATE_SUCCESS:
      departments.splice(index, 1, action.department)
      return {
        ...state,
        departments: departments,
        departmentLoading: false,
        selectedDepartment: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.DEPARTMENT_DELETE_SUCCESS:
      departments.splice(index, 1)
      return {
        ...state,
        departments: departments,
        departmentLoading: false,
        selectedDepartment: null,
        error: null,
      };

    case ActionTypes.DEPARTMENT_SELECT:
      return {
        ...state,
        selectedDepartment: action.selectedDepartment,
        actionStatus: null
      };
    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        departmentLoading: false,
      }
    default:
      return state;
  }
};

export default DepartmentReducer;

