import * as ActionTypes from '../ActionTypes';

const initState = {
  templates: [],
  selectedTemplate: null,
  marketLoading: false,
  error: null,
  actionStatus: null
};

const TemplateReducer = (state = initState, action) => {
  const templates = [...state.templates];
  const index = templates.findIndex((account) => account.id === state.selectedTemplate)
  switch (action.type) {
    case ActionTypes.TEMPLATES_LOADING:
      case ActionTypes.TEMPLATE_CREATING:
      case ActionTypes.TEMPLATE_UPDATING:
      case ActionTypes.TEMPLATE_DELETING:
      return {
        ...state,
        templateLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.TEMPLATES_LOAD_FAILED:
      case ActionTypes.TEMPLATE_CREATE_FAILED:
      case ActionTypes.TEMPLATE_UPDATE_FAILED:
      case ActionTypes.TEMPLATE_DELETE_FAILED:
      return {
        ...state,
        templateLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.TEMPLATES_LOAD_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        templateLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.template],
        templateLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.TEMPLATE_UPDATE_SUCCESS:
      templates.splice(index, 1, action.template)
      return {
        ...state,
        templates: templates,
        templateLoading: false,
        selectedTemplate: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.TEMPLATE_DELETE_SUCCESS:
      templates.splice(index, 1)
      return {
        ...state,
        templates: templates,
        templateLoading: false,
        selectedTemplate: null,
        error: null,
      };

    case ActionTypes.TEMPLATE_SELECT:
      return {
        ...state,
        actionStatus: null,
        selectedTemplate: action.selectedTemplate,
        error: null,
      };

    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        templateLoading: false,
      }
    default:
      return state;
  }
};

export default TemplateReducer;

