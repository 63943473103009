
import { GetMarketingsService, CreateMarketingsService, UpdateMarketingsService, DeleteMarketingsService } from '../../services/MarketingServices';
import * as ActionTypes from '../ActionTypes';

export const LoadMarketings = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.MARKETINGS_LOADING });
        GetMarketingsService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.MARKETINGS_LOAD_SUCCESS, marketings: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.MARKETINGS_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.MARKETINGS_LOAD_FAILED, message: error.message })
        })
    }
}


export const CreateMarketing = (data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.MARKETING_CREATING });
        CreateMarketingsService(data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.MARKETING_CREATE_SUCCESS, marketing: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.MARKETING_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.MARKETINGS_LOAD_FAILED, message: error.message });
        })
    }
}

export const UpdateMarketing = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.MARKETING_UPDATING });
        UpdateMarketingsService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.MARKETING_UPDATE_SUCCESS, marketing: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.MARKETING_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.MARKETING_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeleteMarketing = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.MARKETING_DELETING });
        dispatch(SelectMarketing(id))
        DeleteMarketingsService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.MARKETING_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.MARKETING_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.MARKETING_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectMarketing = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MARKETING_SELECT,
            selectedMarketing: id
        })
    }
}