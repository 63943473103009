import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core"
import { useStyles } from "../../styles/styles";

import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import StoreIcon from '@material-ui/icons/Store';
import BuildIcon from '@material-ui/icons/Build';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment'


const menu = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    link: '/dashboard'
  },
  {
    name: 'Accounts',
    icon: <GroupIcon />,
    link: '/accounts'
  },
  {
    name: 'Marketing',
    icon: <StoreIcon />,
    link: '/marketings'
  },
  {
    name: 'Sales Methodologies',
    icon: <BuildIcon />,
    link: ''
  },
  {
    name: 'Template',
    icon: <AssignmentIcon/>,
    link: '/templates'
  },
  {
    name: 'Research',
    icon: <SearchIcon/>,
    link: '/research'
  },
  {
    name: 'Script',
    icon: <DescriptionIcon/>,
    link: '/scripts'
  },
]

const SideBar = () => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.leftSidebar}
    >
      <List>
        {menu.map((menuItem, index) => (
          <ListItem button component="a" key={index} href={menuItem.link}>
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default SideBar;