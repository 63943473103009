import * as ActionTypes from '../ActionTypes';

const initState = {
  personas: [],
  selectedPersona: null,
  personaLoading: false,
  error: null,
  actionStatus: null
};

const PersonaReducer = (state = initState, action) => {
  const personas = [...state.personas];
  const index = personas.findIndex((persona) => persona.id === state.selectedPersona)
  switch (action.type) {
    case ActionTypes.PERSONAS_LOADING:
    case ActionTypes.PERSONA_CREATING:
    case ActionTypes.PERSONA_UPDATING:
    case ActionTypes.PERSONA_DELETING:
      return {
        ...state,
        personaLoading: true,
        actionStatus: 'pending'
      };
    case ActionTypes.PERSONAS_LOAD_FAILED:
    case ActionTypes.PERSONA_CREATE_FAILED:
    case ActionTypes.PERSONA_UPDATE_FAILED:
    case ActionTypes.PERSONA_DELETE_FAILED:
      return {
        ...state,
        personaLoading: false,
        error: action.message,
        actionStatus: 'failed'
      };

    case ActionTypes.PERSONAS_LOAD_SUCCESS:
      return {
        ...state,
        personas: action.personas,
        personaLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PERSONA_CREATE_SUCCESS:
      return {
        ...state,
        personas: [...state.personas, action.persona],
        personaLoading: false,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PERSONA_UPDATE_SUCCESS:
      personas.splice(index, 1, action.persona)
      return {
        ...state,
        personas: personas,
        personaLoading: false,
        selectedPersona: null,
        error: null,
        actionStatus: 'success'
      };

    case ActionTypes.PERSONA_DELETE_SUCCESS:
      personas.splice(index, 1)
      return {
        ...state,
        personas: personas,
        personaLoading: false,
        selectedPersona: null,
        error: null,
      };

    case ActionTypes.PERSONA_SELECT:
      return {
        ...state,
        selectedPersona: action.selectedPersona,
        actionStatus: null
      };
    case ActionTypes.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        personaLoading: false,
      }
    default:
      return state;
  }
};

export default PersonaReducer;

