
import { GetTemplatesService, CreateTemplatesService, UpdateTemplatesService, DeleteTemplatesService } from '../../services/TemplateServices';
import * as ActionTypes from '../ActionTypes';

export const LoadTemplates = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.TEMPLATES_LOADING });
        GetTemplatesService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.TEMPLATES_LOAD_SUCCESS, templates: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.TEMPLATES_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.TEMPLATES_LOAD_FAILED, message: error.message })
        })
    }
}


export const CreateTemplate = (data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.TEMPLATE_CREATING });
        CreateTemplatesService(data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.TEMPLATE_CREATE_SUCCESS, template: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.TEMPLATE_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.TEMPLATES_LOAD_FAILED, message: error.message });
        })
    }
}

export const UpdateTemplate = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.TEMPLATE_UPDATING });
        UpdateTemplatesService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.TEMPLATE_UPDATE_SUCCESS, template: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.TEMPLATE_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.TEMPLATE_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeleteTemplate = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.TEMPLATE_DELETING });
        dispatch(SelectTemplate(id))
        DeleteTemplatesService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.TEMPLATE_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.TEMPLATE_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.TEMPLATE_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectTemplate = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TEMPLATE_SELECT,
            selectedTemplate: id
        })
    }
}