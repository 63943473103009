//Common
export const RESET_ACTION_STATUS = 'RESET_ACTION_STATUS'

//Auth Actions
export const RESTART_AUTH_RESPONSE = 'RESTART_AUTH_RESPONSE';
export const LOADING = 'LOADING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CODE_ERROR = 'CODE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR';

//Account Actions
export const ACCOUNTS_LOADING = 'ACCOUNTS_LOADING';
export const ACCOUNTS_LOAD_SUCCESS = 'ACCOUNTS_LOAD_SUCCESS';
export const ACCOUNTS_LOAD_FAILED = 'ACCOUNTS_LOAD_FAILED';
export const ACCOUNT_CREATING = 'ACCOUNT_CREATING';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILED = 'ACCOUNT_CREATE_FAILED';
export const ACCOUNT_UPDATING = 'ACCOUNT_UPDATING';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILED = 'ACCOUNT_UPDATE_FAILED';
export const ACCOUNT_DELETING = 'ACCOUNT_DELETING';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAILED = 'ACCOUNT_DELETE_FAILED';
export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';

//Department Actions
export const DEPARTMENTS_LOADING = 'DEPARTMENTS_LOADING';
export const DEPARTMENTS_LOAD_SUCCESS = 'DEPARTMENTS_LOAD_SUCCESS';
export const DEPARTMENTS_LOAD_FAILED = 'DEPARTMENTS_LOAD_FAILED';
export const DEPARTMENT_CREATING = 'DEPARTMENT_CREATING';
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS';
export const DEPARTMENT_CREATE_FAILED = 'DEPARTMENT_CREATE_FAILED';
export const DEPARTMENT_UPDATING = 'DEPARTMENT_UPDATING';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';
export const DEPARTMENT_DELETING = 'DEPARTMENT_DELETING';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAILED = 'DEPARTMENT_DELETE_FAILED';
export const DEPARTMENT_SELECT = 'DEPARTMENT_SELECT';

//Persona Actions
export const PERSONAS_LOADING = 'PERSONAS_LOADING';
export const PERSONAS_LOAD_SUCCESS = 'PERSONAS_LOAD_SUCCESS';
export const PERSONAS_LOAD_FAILED = 'PERSONAS_LOAD_FAILED';
export const PERSONA_CREATING = 'PERSONA_CREATING';
export const PERSONA_CREATE_SUCCESS = 'PERSONA_CREATE_SUCCESS';
export const PERSONA_CREATE_FAILED = 'PERSONA_CREATE_FAILED';
export const PERSONA_UPDATING = 'PERSONA_UPDATING';
export const PERSONA_UPDATE_SUCCESS = 'PERSONA_UPDATE_SUCCESS';
export const PERSONA_UPDATE_FAILED = 'PERSONA_UPDATE_FAILED';
export const PERSONA_DELETING = 'PERSONA_DELETING';
export const PERSONA_DELETE_SUCCESS = 'PERSONA_DELETE_SUCCESS';
export const PERSONA_DELETE_FAILED = 'PERSONA_DELETE_FAILED';
export const PERSONA_SELECT = 'PERSONA_SELECT';

//Mareking Actions
export const MARKETINGS_LOADING = 'MARKETINGS_LOADING';
export const MARKETINGS_LOAD_SUCCESS = 'MARKETINGS_LOAD_SUCCESS';
export const MARKETINGS_LOAD_FAILED = 'MARKETINGS_LOAD_FAILED';
export const MARKETING_CREATING = 'MARKETING_CREATING';
export const MARKETING_CREATE_SUCCESS = 'MARKETING_CREATE_SUCCESS';
export const MARKETING_CREATE_FAILED = 'MARKETING_CREATE_FAILED';
export const MARKETING_UPDATING = 'MARKETING_UPDATING';
export const MARKETING_UPDATE_SUCCESS = 'MARKETING_UPDATE_SUCCESS';
export const MARKETING_UPDATE_FAILED = 'MARKETING_UPDATE_FAILED';
export const MARKETING_DELETING = 'MARKETING_DELETING';
export const MARKETING_DELETE_SUCCESS = 'MARKETING_DELETE_SUCCESS';
export const MARKETING_DELETE_FAILED = 'MARKETING_DELETE_FAILED';
export const MARKETING_SELECT = 'MARKETING_SELECT';

//Template Actions
export const TEMPLATES_LOADING = 'TEMPLATES_LOADING';
export const TEMPLATES_LOAD_SUCCESS = 'TEMPLATES_LOAD_SUCCESS';
export const TEMPLATES_LOAD_FAILED = 'TEMPLATES_LOAD_FAILED';
export const TEMPLATE_CREATING = 'TEMPLATE_CREATING';
export const TEMPLATE_CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS';
export const TEMPLATE_CREATE_FAILED = 'TEMPLATE_CREATE_FAILED';
export const TEMPLATE_UPDATING = 'TEMPLATE_UPDATING';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_FAILED = 'TEMPLATE_UPDATE_FAILED';
export const TEMPLATE_DELETING = 'TEMPLATE_DELETING';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_FAILED = 'TEMPLATE_DELETE_FAILED';
export const TEMPLATE_SELECT = 'TEMPLATE_SELECT';

//Prospect Actions
export const PROSPECTS_LOADING = 'PROSPECTS_LOADING';
export const PROSPECTS_LOAD_SUCCESS = 'PROSPECTS_LOAD_SUCCESS';
export const PROSPECTS_LOAD_FAILED = 'PROSPECTS_LOAD_FAILED';
export const PROSPECT_CREATING = 'PROSPECT_CREATING';
export const PROSPECT_CREATE_SUCCESS = 'PROSPECT_CREATE_SUCCESS';
export const PROSPECT_CREATE_FAILED = 'PROSPECT_CREATE_FAILED';
export const PROSPECT_UPDATING = 'PROSPECT_UPDATING';
export const PROSPECT_UPDATE_SUCCESS = 'PROSPECT_UPDATE_SUCCESS';
export const PROSPECT_UPDATE_FAILED = 'PROSPECT_UPDATE_FAILED';
export const PROSPECT_DELETING = 'PROSPECT_DELETING';
export const PROSPECT_DELETE_SUCCESS = 'PROSPECT_DELETE_SUCCESS';
export const PROSPECT_DELETE_FAILED = 'PROSPECT_DELETE_FAILED';
export const PROSPECT_SELECT = 'PROSPECT_SELECT';