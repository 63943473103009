import HttpService from './HttpService';

export const GetPersonasService = (id) => {
    const http = new HttpService();
    const url = `departments/${id}/personas`;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const CreatePersonasService = (inputData, id) => {
    const http = new HttpService();
    const url = `departments/${id}/personas/create`;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const UpdatePersonasService = (id, inputData) => {
    const http = new HttpService();
    const url = "personas/update/" + id;
    const tokenId = "user-token";

    return http.postData(inputData, url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const DeletePersonasService = (id) => {
    const http = new HttpService();
    const url = "personas/delete/" + id;
    const tokenId = "user-token";

    return http.getData(url, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}
