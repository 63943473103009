import React from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import { useStyles } from '../../styles/styles';
import { useTheme } from '@material-ui/core';

export default function Spinner () {

    const theme = useTheme()
    const classes = useStyles()

    return (
        <div className={classes.spinnerContainer}>
            <FadeLoader color={theme.palette.primary.dark} />
        </div>
    )
}