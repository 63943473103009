import React, { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import { CreatePersona, SelectPersona, UpdatePersona } from '../../../redux/actions/PersonaActions';

export default function PersonaEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');

  const { selectedDepartment } = useSelector(state => state.departments);
  const { personas, selectedPersona, personaLoading, error, actionStatus } = useSelector(state => state.personas);

  useEffect(() => {
    if (!selectedDepartment) {
      history.push('/accounts');
    }
  }, [selectedDepartment, history])

  useEffect(() => {
    if (personas && selectedPersona) {
      const persona = personas.find((persona) => persona.id === selectedPersona)
      setName(persona.name);
    }
  }, [selectedPersona, personas])

  const handleCancel = () => {
    if (selectedPersona) {
      dispatch(SelectPersona(null))
    }
    history.push(`/department/${selectedDepartment}/personas`)
  }

  const handlePersona = () => {
    if (selectedPersona) {
      if (name) {
        dispatch(UpdatePersona(selectedPersona, {
          name: name
        }))
      }
    } else {
      if (name) {
        dispatch(CreatePersona({
          name: name
        }, selectedDepartment))
      }
    }
  }

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push(`/department/${selectedDepartment}/personas`)
    }
  }, [actionStatus, history, selectedDepartment])

  if (personaLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedPersona ? 'Edit Persona' : 'Create Persona'}</Typography>
          <Box paddingY={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handlePersona()}>
            {selectedPersona ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}