import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography, Grid, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import { LoadDepartments } from '../../../redux/actions/DepartmentActions';
import { LoadPersonas } from '../../../redux/actions/PersonaActions';
import { LoadAcccounts } from '../../../redux/actions/AccountActions';
import { CreateMarketing, SelectMarketing, UpdateMarketing } from '../../../redux/actions/MarketingActions';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';

export default function MarketingEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [accountId, setAccountId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [personaId, setPersonaId] = useState(0);
  const [name, setName] = useState('');
  const [keywords, setKeywords] = useState('');
  const [valueProposition, setValueProposition] = useState('');
  const [benefits, setBenefits] = useState('');
  const { accounts, accountLoading } = useSelector(state => state.accounts);
  const { departments, departmentLoading } = useSelector(state => state.departments);
  const { personas, personaLoading } = useSelector(state => state.personas);
  const { marketing, selectedMarketing, marketingLoading, error, actionStatus } = useSelector(state => state.marketing);
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    dispatch(LoadAcccounts())
    dispatch(ResetActionStatus())
  }, [dispatch])

  useEffect(() => {
    if (selectedMarketing && marketing.length > 0) {
      const marketing = marketing.find(marketing => marketing.id === selectedMarketing)
      setName(marketing.name)
      setKeywords(marketing.keywords)
      setValueProposition(marketing.value_proposition)
      setBenefits(marketing.benefits)
      setAccountId(marketing.account_id)
      setDepartmentId(marketing.department_id)
      setPersonaId(marketing.persona_id)
    }
  }, [selectedMarketing, marketing])

  useEffect(() => {
    if (accountId) {
      dispatch(LoadDepartments(accountId))
    }
  }, [accountId, dispatch])

  useEffect(() => {
    if (departmentId) {
      dispatch(LoadPersonas(departmentId))
    }
  }, [departmentId, dispatch])

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push(`/marketing
      `)
    }
  }, [actionStatus, history])

  const handleMarketing = () => {
    const error = {};
    if (!name) {
      error.name = 'Name is Required';
    }
    if (!accountId) {
      error.account = 'Account is Required';
    }
    if (!departmentId) {
      error.department = 'Deparment is Required';
    }
    if (!personaId) {
      error.persona = 'Persona is Required';
    }
    if (!keywords) {
      error.keywords = 'Keywords is Required';
    }
    if (!valueProposition) {
      error.valueProposition = 'Value Proposition is Required';
    }
    if (!benefits) {
      error.benefits = 'Benefits is Required';
    }
    setValidationError(error)
    if (Object.keys(error).length > 0) {
      return
    }

    const data = {
      account_id: accountId,
      department_id: departmentId,
      persona_id: personaId,
      keywords: keywords,
      value_proposition: valueProposition,
      benefits: benefits,
      name: name
    }

    if (selectedMarketing) {
      dispatch(UpdateMarketing(selectedMarketing, data))
    } else {
      dispatch(CreateMarketing(data))
    }
  }

  const handleCancel = () => {
    if (selectedMarketing) {
      dispatch(SelectMarketing(null))
    }
    history.push('/marketing')
  }

  if (accountLoading || departmentLoading || personaLoading || marketingLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedMarketing ? 'Edit Marketing Knowledge' : 'Create Marketing Knowledge'}</Typography>
          <Box my={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => { setName(e.target.value) }}
                  error={!!validationError.name}
                  helperText={validationError.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" fullWidth error={!!validationError.account} required>
                  <InputLabel id="account-label">Account</InputLabel>
                  <Select
                    labelId="account-label"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                    placeholder="Select Account"
                    label="Account"
                  >
                    <MenuItem value={0}>Select Account</MenuItem>
                    {
                      accounts.map(account => (
                        <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem>
                      ))
                    }
                  </Select>
                  {
                    validationError.account &&
                    <FormHelperText>{validationError.account}</FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" fullWidth disabled={!accountId} error={!!validationError.department} required>
                  <InputLabel id="department-label">Deparmtment</InputLabel>
                  <Select
                    labelId="department-label"
                    value={departmentId}
                    onChange={(e) => setDepartmentId(e.target.value)}
                    placeholder="Select Deparmtment"
                    label="Deparmtment"
                  >
                    <MenuItem value={0}>Select Deparmtment</MenuItem>
                    {
                      departments.map(department => (
                        <MenuItem value={department.id} key={department.id}>{department.name}</MenuItem>
                      ))
                    }
                  </Select>
                  {
                    validationError.department &&
                    <FormHelperText>{validationError.department}</FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl variant="outlined" fullWidth disabled={!accountId || !departmentId} error={!!validationError.persona} required>
                  <InputLabel id="persona-label">Persona</InputLabel>
                  <Select
                    labelId="persona-label"
                    value={personaId}
                    onChange={(e) => setPersonaId(e.target.value)}
                    placeholder="Select Persona"
                    label="Persona"
                  >
                    <MenuItem value={0}>Select Persona</MenuItem>
                    {
                      personas.map(persona => (
                        <MenuItem value={persona.id} key={persona.id}>{persona.name}</MenuItem>
                      ))
                    }
                  </Select>
                  {
                    validationError.persona &&
                    <FormHelperText>{validationError.persona}</FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Keywords (seperate by comma)"
                  variant="outlined"
                  fullWidth
                  value={keywords}
                  onChange={(e) => { setKeywords(e.target.value) }}
                  error={!!validationError.keywords}
                  helperText={validationError.keywords}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Value Proposition"
                  variant="outlined"
                  fullWidth
                  value={valueProposition}
                  onChange={(e) => { setValueProposition(e.target.value) }}
                  error={!!validationError.valueProposition}
                  helperText={validationError.valueProposition}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Benefits"
                  variant="outlined"
                  fullWidth
                  value={benefits}
                  onChange={(e) => { setBenefits(e.target.value) }}
                  error={!!validationError.benefits}
                  helperText={validationError.benefits}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleMarketing()}>
            {selectedMarketing ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}