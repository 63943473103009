
import { GetPersonasService, CreatePersonasService, UpdatePersonasService, DeletePersonasService } from '../../services/PersonaServices';
import * as ActionTypes from '../ActionTypes';

export const LoadPersonas = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PERSONAS_LOADING });
        GetPersonasService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PERSONAS_LOAD_SUCCESS, personas: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PERSONAS_LOAD_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PERSONAS_LOAD_FAILED, message: error.message })
        })
    }
}

export const CreatePersona = (data, id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PERSONA_CREATING });
        CreatePersonasService(data, id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PERSONA_CREATE_SUCCESS, persona: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PERSONA_CREATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PERSONA_CREATE_FAILED, message: error.message });
        })
    }
}

export const UpdatePersona = (id, data) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PERSONA_UPDATING });
        UpdatePersonasService(id, data).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PERSONA_UPDATE_SUCCESS, persona: res.data });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PERSONA_UPDATE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PERSONA_UPDATE_FAILED, message: error.message });
        })
    }
}

export const DeletePersona = (id) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PERSONA_DELETING });
        dispatch(SelectPersona(id))
        DeletePersonasService(id).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.PERSONA_DELETE_SUCCESS });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.PERSONA_DELETE_FAILED, message: res.message });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
            dispatch({ type: ActionTypes.PERSONA_DELETE_FAILED, message: error.message });
        })
    }
}


export const SelectPersona = (id) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PERSONA_SELECT,
            selectedPersona: id
        })
    }
}