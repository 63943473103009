import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideBar from './components/layouts/SideBar';
import AccountEdit from './components/pages/accounts/AccountEdit';
import AccountList from './components/pages/accounts/AccountList';
import DepartmentEdit from './components/pages/departments/DepartmentEdit';
import DepartmentList from './components/pages/departments/DepartmentList';
import PersonaEdit from './components/pages/personas/PersonaEdit';
import PersonaList from './components/pages/personas/PersonaList';
import MarketingList from './components/pages/marketings/MarketingList';
import MarketingEdit from './components/pages/marketings/MarketingEdit';

import Profile from './components/pages/ProfileComponent';
import { useStyles } from './styles/styles';
import TemplateList from './components/pages/template/TemplateList';
import TemplateEdit from './components/pages/template/TemplateEdit';
import Script from './components/pages/script/Script';
import Research from './components/pages/research/Research';
import ProspectList from './components/pages/prospect/ProspectList';
import ProspectEdit from './components/pages/prospect/ProspectEdit';

export default function UserPrivateRoute(props) {
  const classes = useStyles()
  return (
    <div>
      <SideBar />
      <div className={classes.mainContainer}>
        <Switch>

          <Route exact path={`/accounts`} component={AccountList} />
          <Route exact path={`/account/create`} component={AccountEdit} />
          <Route exact path={`/account/edit/:id`} component={AccountEdit} />

          <Route exact path={`/account/:id/departments`} component={DepartmentList} />
          <Route exact path={`/account/:id/department/create`} component={DepartmentEdit} />
          <Route exact path={`/department/edit/:id`} component={DepartmentEdit} />

          <Route exact path={`/department/:id/personas`} component={PersonaList} />
          <Route exact path={`/department/:id/persona/create`} component={PersonaEdit} />
          <Route exact path={`/persona/edit/:id`} component={PersonaEdit} />

          <Route exact path={`/persona/:id/prospects`} component={ProspectList} />
          <Route exact path={`/persona/:id/prospect/create`} component={ProspectEdit} />
          <Route exact path={`/prospect/edit/:id`} component={ProspectEdit} />

          <Route exact path={`/marketings`} component={MarketingList} />
          <Route exact path={`/marketing/create`} component={MarketingEdit} />
          <Route exact path={`/marketing/edit/:id`} component={MarketingEdit} />

          <Route exact path={`/templates`} component={TemplateList} />
          <Route exact path={`/template/create`} component={TemplateEdit} />
          <Route exact path={`/template/edit/:id`} component={TemplateEdit} /> 

          <Route exact path={`/research`} component={Research} />

          <Route exact path={`/scripts`} component={Script} />

          <Route exact path={`/user/view-profile`} component={Profile} />
          <Route exact path={props.match.path} render={props => (
            <Redirect to={{ pathname: `/user/view-profile` }} />
          )} />
        </Switch>
      </div>
    </div>
  );
}
