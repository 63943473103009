import React, { useEffect } from 'react';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMarketing, SelectMarketing } from '../../../redux/actions/MarketingActions';
import { ResetActionStatus } from '../../../redux/actions/CommonAction';
import Spinner from '../../common/Spinner';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import { LoadMarketings } from '../../../redux/actions/MarketingActions';

export default function MarketingList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { marketings, marketingLoading, error } = useSelector(state => state.marketings);

  useEffect(() => {
    dispatch(ResetActionStatus())
    dispatch(LoadMarketings())
  }, [dispatch])

  const handleCreateMarketing = () => {
    dispatch(SelectMarketing(null))
    history.push('/marketing/create')
  }

  const handleEdit = (id) => {
    dispatch(SelectMarketing(id))
    history.push('/marketing/edit/' + id)
  }

  const handleDelete = (id) => {
    dispatch(DeleteMarketing(id))
  }

  if (marketingLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      {
        marketings.length === 0 ? (
          <>
            <Box className={classes.fullContainer}>
              <AccountBoxIcon style={{fontSize: 48}} />
              <Typography variant="h5">No Marketing</Typography>
              <Typography variant="body1" style={{marginBottom: 15}}>Please create an marketing</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateMarketing}>Create Marketing</Button>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Typography variant="h5">Marketings</Typography>
              <Button size="small" variant="contained" color="primary" onClick={handleCreateMarketing}>Add Marketing</Button>
            </Box>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Account</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Persona</TableCell>
                    <TableCell>Keywords</TableCell>
                    <TableCell>Benefits</TableCell>
                    <TableCell>Value Proposition</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    marketings.map((marketing) => (
                      <TableRow key={marketing.id}>
                        <TableCell>{marketing.id}</TableCell>
                        <TableCell>{marketing.account_name}</TableCell>
                        <TableCell>{marketing.department_name}</TableCell>
                        <TableCell>{marketing.persona_name}</TableCell>
                        <TableCell>{marketing.keywords}</TableCell>
                        <TableCell>{marketing.benefits}</TableCell>
                        <TableCell>{marketing.value_proposition}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(marketing.id)}><EditIcon color="primary"/></IconButton>
                          <IconButton onClick={() => handleDelete(marketing.id)}><DeleteIcon color="error"/></IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </>
  )
}