import React, { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import { CreateDepartment, SelectDepartment, UpdateDepartment } from '../../../redux/actions/DepartmentActions';

export default function DepartmentEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');

  const { selectedAccount } = useSelector(state => state.accounts);
  const { departments, selectedDepartment, departmentLoading, error, actionStatus } = useSelector(state => state.departments);

  useEffect(() => {
    if (!selectedAccount) {
      history.push('/accounts');
    }
  }, [selectedAccount, history])

  useEffect(() => {
    if (departments && selectedDepartment) {
      const department = departments.find((department) => department.id === selectedDepartment)
      setName(department.name);
    }
  }, [selectedDepartment, departments])

  const handleCancel = () => {
    if (selectedDepartment) {
      dispatch(SelectDepartment(null))
    }
    history.push(`/account/${selectedAccount}/departments`)
  }

  const handleDepartment = () => {
    if (selectedDepartment) {
      if (name) {
        dispatch(UpdateDepartment(selectedDepartment, {
          name: name
        }))
      }
    } else {
      if (name) {
        dispatch(CreateDepartment({
          name: name
        }, selectedAccount))
      }
    }
  }

  useEffect(() => {
    if (actionStatus === 'success') {
      history.push(`/account/${selectedAccount}/departments`)
    }
  }, [actionStatus, history, selectedAccount])

  if (departmentLoading) {
    return <Spinner />
  }

  return (
    <>
      {
        error && (
          <Box>{error}</Box>
        )
      }
      <Card>
        <CardContent>
          <Typography variant="h5">{selectedDepartment ? 'Edit Department' : 'Create Department'}</Typography>
          <Box paddingY={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleDepartment()}>
            {selectedDepartment ? 'Update' : 'Create'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </>
  )
}